
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor from 'react-scrollable-anchor';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { Layout, Seo } from 'components';

import { appContext } from 'providers/appProvider';

import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import './signup.scss';
import './procare-signup.scss';
import axios from 'axios';

const DATA_API_URL = 'https://main--elastic-jungle-a5655b.netlify.app/.netlify/functions/procare-signup';
const RECAPTCHA_SITE_KEY = '6Lfnl74dAAAAAGI5DvYmNlaLLsbtWTL2K6HGuqhM';

const ProcareSignup = props => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.ndSignup;
  const recaptchaRef = useRef();

  const pageTitle = 'NUTRITION DIRECT™ | Sign Up';
  const pageDescription = 'Sign up for updates and resources';

  useEffect(() => {
    loadReCaptcha(RECAPTCHA_SITE_KEY, () => { });
  });

  const renderPage = () => {
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      RecaptchaToken: '',
    });
    const [errors, setErrors] = useState({
      firstName: '',
      lastName: '',
      email: '',
    });

    function handleChange(key, value) {
      formData[key] = value;
      setFormData({ ...formData });
    }

    function handleBlur(key, value) {
      errors[key] = !value ? 'required' : '';
      if (key === 'email' && !validateEmail(formData.email)) {
        errors.email = 'Please enter valid email';
      }
      setErrors({ ...errors });
    }

    const validateEmail = email => {
      const emailREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailREGEX.test(email);
    };

    function errorCheck() {
      let isError = false;
      Object.keys(formData).forEach(key => {
        if (!formData[key]) {
          isError = true;
          errors[key] = 'required';
        }
      });
      if (!validateEmail(formData.email)) {
        errors.email = 'Please enter valid email';
        isError = true;
      }
      setErrors({ ...errors });
      return isError;
    }

    async function handleFormSubmit(e) {
      e.preventDefault();
      const isError = errorCheck();
      if (!isError) {
        recaptchaRef?.current && recaptchaRef.current.execute();
        await axios({
          url: DATA_API_URL,
          method: 'POST',
          data: JSON.stringify(formData),
        })
          .then(() => {
            setFormSubmitted(true);
            setFormData({});
          })
          .catch(error => console.log(error.response));
      }
      console.log(isError);
    }

    return (
      <div id="procare-signup">
        <ScrollableAnchor id="top">
          <div />
        </ScrollableAnchor>
        <ContentBlock>
          <Grid fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row>
              <Col xs={12}>
                <ColorBlock white>
                  <div className="signup-form-container">
                    {!formSubmitted ? (
                      <form onSubmit={handleFormSubmit}>
                        <h2>
                          Register to Receive Support from Nutrition Direct™
                        </h2>
                        <p>
                          Nutrition after weight loss surgery shouldn’t be
                          complicated. Sign up to receive ongoing support that
                          makes maintaining a healthy lifestyle as simple as
                          possible. You can unsubscribe at any time.
                        </p>
                        <p className="strong">
                          All fields are required unless marked optional.
                        </p>
                        <form className="signup-form">
                          <div
                            className={`form-group ${errors.firstName ? 'form-error' : ''
                              }`}
                          >
                            <label htmlFor="fname">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="First_Name"
                              name="First_Name"
                              maxLength={50}
                              onBlur={e =>
                                handleBlur('firstName', e.target.value)
                              }
                              onChange={e =>
                                handleChange('firstName', e.target.value)
                              }
                            />
                          </div>

                          <div
                            className={`form-group ${errors.lastName ? 'form-error' : ''
                              }`}
                          >
                            <label htmlFor="fname">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="Last_Name"
                              name="Last_Name"
                              onBlur={e =>
                                handleBlur('lastName', e.target.value)
                              }
                              maxLength={50}
                              onChange={e =>
                                handleChange('lastName', e.target.value)
                              }
                            />
                          </div>

                          <div
                            className={`form-group ${errors.email ? 'form-error' : ''
                              }`}
                          >
                            <label htmlFor="Email">Email</label>
                            <input
                              className="form-control form-email"
                              id="Email"
                              name="Email"
                              onBlur={e => handleBlur('email', e.target.value)}
                              maxLength={100}
                              onChange={e =>
                                handleChange('email', e.target.value)
                              }
                            />
                          </div>
                          <p>
                            <strong>Yes,</strong> I would like to receive tools,
                            resources, and helpful information about Nutrition
                            Direct™.
                          </p>
                          <p>
                            Endo Pharmaceuticals Inc. understands that your
                            privacy is important. By providing your name,
                            address, and other requested information, you are
                            giving Endo Pharmaceuticals Inc. and other parties
                            working with us permission to communicate with you
                            about Nutrition Direct™. We will not sell your name
                            or other personal information to any party for its
                            marketing use. To view the privacy policy, please
                            visit{' '}
                            <a
                              href="https://www.endo.com/privacy-legal"
                              target="_blank"
                            >
                              Endo.com/privacy-legal
                            </a>
                            .
                          </p>
                          <ReCaptcha
                            sitekey={RECAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                            verifyCallback={token =>
                              handleChange('RecaptchaToken', token)
                            }
                          />
                          <div className="btn-wrap">
                            <button
                              type="submit"
                              className="nascobal-btn sign-up-form-nd"
                            >
                              SIGN UP
                            </button>
                          </div>
                        </form>
                      </form>
                    ) : (
                      <div>
                        <h2>
                          Thank you for signing up. You will hear from us
                          shortly.
                        </h2>
                      </div>
                    )}
                  </div>
                </ColorBlock>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    );
  };
  return (
    <Layout indication={indication} className="nutrition-precare-signup">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default ProcareSignup;
